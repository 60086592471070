<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ isEditMode? "Edit ": "Add New " }} Template</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.name"
                label="Template Name *"
                required
                :error="!(field.name && field.name.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <div class="placeHoldersWrapper">
              <div class="label">
                Select a placeholder to insert:
              </div>
              <div class="placeholderSelectorWrapper">
                <label class="dropdown">

                  <div class="dd-button">
                    Select
                  </div>

                  <input
                    id="test"
                    type="checkbox"
                    class="dd-input"
                  >

                  <ul class="dd-menu">
                    <li @click="insertPlaceHolder('{{FirstName}}')">First Name</li>
                    <li @click="insertPlaceHolder('{{LastName}}')">Last Name</li>
                  </ul>

                </label>
              </div>
            </div>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                ref="textField"
                v-model="field.body"
                label="Template Body"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* Indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
        >
          {{ isEditMode? "Update": "Add" }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    name: 'TemplateAddEditDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      selectedTemplate: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      notification: '',
      field: {
        name: '',
        body: '',
      },
      isFormDirty: false,
    }),

    watch: {
      isVisible (val) {
        if (val) {
          this.isFormDirty = false
          // Dialog opened
          if (this.isEditMode) {
            // Opening in edit mode
            // Assign exisiting entry values
            if (this.selectedTemplate.name && this.selectedTemplate.name.length) {
              this.field.name = this.selectedTemplate.name
            }
            if (this.selectedTemplate.templateBody && this.selectedTemplate.templateBody.length) {
              this.field.body = this.selectedTemplate.templateBody
            }
          } else {
            // Opening in add mode
            // Reset all fields
            this.field = Object.assign({
              name: '',
              body: '',
            })
          }
        } else {
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
      },

      close () {
        this.$emit('on-close')
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.name || !this.field.name.length) {
          this.notification = 'Please enter a name of the template'
          return false
        }
        if (!this.field.body || !this.field.body.length) {
          this.notification = 'Template body can not be blank'
          return false
        }
        return true
      },

      insertPlaceHolder (placeholder) {
        // this.field.body = `${this.field.body} ${placeholder}`
        // Get the input element by tag name, using the ref as a base for the search
        // This is more vue-friendly and plays nicer when you duplicate components
        const el = this.$refs.textField.$el.querySelector('textarea')

        // Insert text into current position
        let cursorPos = el.selectionEnd // Get current Position
        this.field.body =
          `${this.field.body.substring(0, cursorPos)} ${placeholder} ${this.field.body.substring(cursorPos)}`

        // Get new cursor position
        cursorPos += this.field.body.length
      },

      submit () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }
        if (this.isEditMode) {
          // Update
          const payload = {
            TemplateBody: this.field.body,
            Name: this.field.name,
            Username: this.username,
            TemplateID: this.selectedTemplate.templateID,
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}template/create?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        } else {
          // Add
          const payload = {
            TemplateBody: this.field.body,
            Name: this.field.name,
            Username: this.username,
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}template/create?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>
<style scoped>
.placeHoldersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px;
  margin-top: 25px;
  margin-bottom: 15px;
  background-color: rgb(212, 149, 0);
  border-radius: 8px;
}
.label {
  color: white;
  font-weight: 600;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px 80px 4px 60px;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
}

.dd-button:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:hover + .dd-menu {
  display: block;
}

.dd-menu li {
  width: 180px;
  padding: 0px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider{
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
</style>
